import React from 'react';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import { useRouter } from 'next/router';
import { Result } from 'antd';
import StudioLogo from '@ozoneuniverse/ui-components/build-pkg/src/components/logo/StudioLogo';
import { S3_IMAGES_URL } from 'util/constants';
import styles from './404.module.css';

const MissingPage = () => {
  const router = useRouter();

  return (
    <div className='missing-page-root'>
      <div className={styles.studioLogo}>
        <StudioLogo s3ImagesUrl={S3_IMAGES_URL as string} inEditor={false} />
      </div>
      <img
        className={styles.image}
        src='https://d3to9p4w6lww5g.cloudfront.net/ozone-website-public/404.jpg'
        alt='404 error'
        width={550}
        height={320}
      />
      <div className={styles.error}>
        <span>404</span>
      </div>
      <div className={styles.info}>
        <span>
          mmmm.. You have stumbled on an invisible part of the{' '}
          <p>metaverse, try to go back a few pages and continue exploring</p>
        </span>
      </div>
      <DefaultButton
        type='primary'
        onClick={() => router.push('/')}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <span style={{ color: '#fff', width: '140px' }}>Back Home</span>
      </DefaultButton>
    </div>
  );
};

export default MissingPage;
